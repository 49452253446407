<template>
  <v-dialog persistent v-model="dialog" width="90%">
    <div>
      <v-card
        class="mx-auto"
        flat
        elevation="1"
        v-if="hoursSelectDates.length > 0"
      >
        <v-app-bar class="mx-auto" color="white" flat dense elevation="0">
          <div class="text-lg-h6">
            <v-icon class="mr-2">calendar_month</v-icon>
            Add New Entry for
            <span class="primary--text">{{ user.name }}</span>
          </div>
          <v-spacer />
          <v-chip label class="primary lighten-4 primary--text text--darken-4">
            Total Hours: {{ totalHours }}
          </v-chip>
        </v-app-bar>
        <v-divider />
        <v-row no-gutters>
          <v-card class="mx-auto" flat>
            <v-form ref="entryForm" v-model="isValid">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4" sm="12">
                    <v-autocomplete
                      v-model="timeSheetLines.project"
                      :items="projects"
                      :item-value="(item) => item.code"
                      :item-text="(item) => item.description"
                      label=""
                      filled
                      placeholder="Select Project"
                      dense
                      @change="onSelectProject"
                      :rules="[rules.required]"
                    ></v-autocomplete>
                    <v-autocomplete
                      v-model="timeSheetLines.projectActivity"
                      :items="activities"
                      :item-value="(item) => item.activitiesCode"
                      :item-text="(item) => item.description"
                      label=""
                      filled
                      dense
                      class="mt-n3"
                      placeholder="Enter Activity"
                      clearable
                    />
                    <v-autocomplete
                      v-model="timeSheetLines.shortcutDimension5Code"
                      :items="dimensions"
                      :item-value="(item) => item.code"
                      :item-text="(item) => item.displayName"
                      label=""
                      filled
                      dense
                      class="mt-n3"
                      placeholder="Select Staff Dimension"
                      clearable
                    />
                    <v-autocomplete
                      v-model="timeSheetLines.shortcutDimension5CodeValue"
                      :items="dimensionValue"
                      :item-value="(item) => item.code"
                      :item-text="(item) => item.displayName"
                      label=""
                      filled
                      dense
                      class="mt-n3"
                      placeholder="Select Staff Dimension Value"
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" md="8" sm="12">
                    <v-textarea
                      v-model="timeSheetLines.comments"
                      label=""
                      filled
                      clearable
                      dense
                      rows="3"
                      placeholder="Enter Comments"
                      :rules="[rules.required]"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-card-title class="text-body-1 mt-n10 font-weight-bold ml-n2"
                  >Add Hours</v-card-title
                >
                <v-sheet>
                  <v-slide-group show-arrows>
                    <v-slide-item
                      v-for="(item, i) in hoursSelectDates"
                      :key="i"
                      v-slot="{ active }"
                    >
                      <v-card
                        flat
                        tile
                        :color="
                          item.nonworking ? 'grey lighten-3' : 'cyan lighten-4'
                        "
                        :input-value="active"
                        width="50"
                        class="d-flex flex-column align-end mx-auto px-2"
                      >
                        <div class="text-caption mr-1">
                          {{ getDayInt(item.periodStart) }}
                        </div>
                        <div class="text-caption mt-n2 border--bottom">
                          {{ getDay(item.periodStart) }}
                        </div>
                        <v-divider inset />
                        <div
                          class="text-caption my-1 align-self-center primary--text hoursField"
                        >
                          <input
                            max="24"
                            min="0"
                            maxlength="2"
                            id="hoursEntry"
                            :class="{
                              invalid:
                                item.totalHours < 0 || item.totalHours > 24,
                            }"
                            v-model="item.totalHours"
                            type="number"
                            :readonly="item.nonworking"
                          />
                        </div>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
                </v-sheet>
              </v-card-text>
            </v-form>
          </v-card>
        </v-row>
        <v-card-actions>
          <v-btn
            class="deep-orange lighten-4 deep-orange--text text--darken-4"
            elevation="0"
            @click="close()"
          >
            Close
            <v-icon class="mx-2">close</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            class="primary lighten-4 primary--text text--darken-4 text-capitalize mx-2"
            elevation="0"
            :disabled="isEdit"
            @click="addEntry('timesheetClear')"
            >Save & Clear</v-btn
          >
          <v-btn
            class="green text-capitalize"
            elevation="0"
            @click="
              isEdit ? editEntry('timesheetClose') : addEntry('timesheetClose')
            "
            >Save & Close</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="mx-auto" flat elevation="1" v-else>
        <v-row>
          <v-col cols="12" md="4" sm="12">
            <v-skeleton-loader type="text" />
            <v-skeleton-loader type="text" />
          </v-col>
          <v-col cols="12" md="8" sm="12">
            <v-skeleton-loader type="paragraph" />
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-skeleton-loader type="list-item-two-line" />
          </v-col>
        </v-row>
      </v-card>
      <v-overlay
        color="#EBF5FB"
        v-if="$store.getters['Timesheet/timesheetGetters']('loading')"
      >
        <div clas="d-flex flex-column">
          <v-progress-circular
            indeterminate
            size="64"
            width="3"
            color="primary"
          ></v-progress-circular>
          <div class="mt-2 primary--text">saving ....</div>
        </div>
      </v-overlay>
    </div>
  </v-dialog>
</template>
<script>
import moment from "moment";
export default {
  name: "TimesheetCard",
  data: function () {
    return {
      entryActivities: [],
      timeSheetLines: {
        project: "",
        projectActivity: "",
        shortcutDimension5Code: "",
        shortcutDimension5CodeValue: "",
        comments: "",
      },
      isEdit: false,
      dialog: true,
      isValid: false,
      activities: [],
      dates: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Timesheet/getDimensions");
      if (to.query.projectActivity && to.query.project) {
        v.isEdit = true;
        v.$store.dispatch("Timesheet/getLines", {
          employeeNo: v.user.employee,
        });
      }
    });
  },
  mounted: function () {
    this.timeSheetLines.shortcutDimension5Code = `${this.user?.profile?.first_name} ${this.user?.profile?.middle_name} ${this.user?.profile?.last_name}`;
    Event.$on("timesheetClear", () => {
      this.timeSheetLines = {
        project: "",
        projectActivity: "",
        comments: "",
        shortcutDimension5Code: "",
      };
      this.updateDates(this.calender);
    });
    Event.$on("timesheetClose", () => {
      this.close();
    });
    if (this.calender.length > 0) {
      this.updateDates(this.calender);
    }
  },
  computed: {
    dimensions() {
      return this.$store.getters["Timesheet/timesheetGetters"]("dimensions");
    },
    dimensionValue() {
      return (
        this.dimensions?.find(
          (value) =>
            value.code.toLowerCase() ===
            this.timeSheetLines.shortcutDimension5Code.toLowerCase()
        )?.dimensionValues || []
      );
    },
    rules: function () {
      return {
        required: (value) => !!value || "Required.",
      };
    },
    user: function () {
      return this.$store.getters["auth/user"];
    },
    lines: function () {
      return this.$store.getters["Timesheet/timesheetGetters"]("lines");
    },
    calender: function () {
      return this.$store.getters["Timesheet/timesheetGetters"]("calender");
    },
    entryHeaders: function () {
      return [
        {
          text: "Project",
          value: "project",
          align: "left",
          sort: false,
        },
        {
          text: "Budget",
          value: "budget",
          align: "left",
          sort: false,
        },
        {
          text: "Activity",
          value: "activity",
          align: "left",
          sorta: false,
        },
      ];
    },
    projects: function () {
      return this.$store.getters["Timesheet/timesheetGetters"]("projects");
    },
    period: function () {
      return this.$store.getters["Timesheet/timesheetGetters"]("period");
    },
    hoursSelectDates: {
      get() {
        return this.dates;
      },
      set(val) {
        this.dates = val;
      },
    },
    totalHours: function () {
      return this.dates
        .filter((el) => el.totalHours !== "")
        .reduce((acc, el) => parseInt(acc) + parseInt(el.totalHours), 0);
    },
  },
  methods: {
    getDayInt: function (date) {
      return moment(date).format("D");
    },
    getDay: function (date) {
      return moment(date).format("ddd");
    },
    addEntry: function (type) {
      if (!this.isValid) {
        this.$refs.entryForm.validate();
      } else {
        const lines = {
          lines: this.hoursSelectDates
            .filter((el) => el.totalHours !== "")
            .map((el) => {
              this.timeSheetLines.shortcutDimension5Code =
                this.timeSheetLines.shortcutDimension5CodeValue;
              const { shortcutDimension5CodeValue, ...rest } =
                this.timeSheetLines;
              return {
                ...el,
                ...rest,
                totalHours: parseInt(el.totalHours),
                date: el.periodStart,
                employeeNo: this.user.employee,
                periodCode: this.period.startingDate,
              };
            }),
          type,
        };
        this.$store.dispatch("Timesheet/addEntry", lines);
      }
    },
    editEntry: function (type) {
      if (!this.isValid) {
        this.$refs.entryForm.validate();
      } else {
        const lines = {
          lines: this.hoursSelectDates
            .filter((el) => el.totalHours !== "")
            .map((el) => {
              this.timeSheetLines.shortcutDimension5Code =
                this.timeSheetLines.shortcutDimension5CodeValue;
              const { shortcutDimension5CodeValue, ...rest } =
                this.timeSheetLines;
              return {
                ...el,
                ...rest,
                totalHours: parseInt(el.totalHours),
                date: el.periodStart,
                employeeNo: this.user.employee,
                periodCode: this.period.startingDate,
              };
            }),
          type,
        };
        this.$store.dispatch("Timesheet/editEntry", lines);
      }
    },
    onSelectProject: function (project) {
      this.activities = this.projects.find(
        (p) => p.code === project
      ).projectActivities;
    },
    close: function () {
      this.dialog = false;
      this.$router.replace({ name: "TimesheetList" });
    },
    // get calender dates
    updateDates: function (dates) {
      this.dates = dates
        .filter((el) => el.month === this.period.name)
        .map((el) => {
          return {
            ...el,
            totalHours: "",
          };
        });
    },
  },
  watch: {
    calender: {
      handler: function (val) {
        val && this.period
          ? this.dates.length > 0
            ? this.dates
            : this.updateDates(val)
          : [];
      },
      deep: true,
    },
    lines: {
      handler: function (val) {
        if (val.length > 0) {
          const line = val
            .filter((h) => h.periodCode === this.period.startingDate)
            .shift()
            .timeSheetLines.filter(
              (el) =>
                el.projectCode === this.$route.query.project &&
                el.activitiesCode === this.$route.query.projectActivity
            )
            .shift();
          this.activities = this.projects.find(
            (p) => p.code === line.projectCode
          ).projectActivities;
          this.timeSheetLines.project = line.projectCode;
          this.timeSheetLines.projectActivity = line.activitiesCode;
          this.dates = line.calenderDays.map((el) => {
            el.totalHours = "";
            el.periodStart = el.date;
            if (el.line !== null) {
              if (
                parseInt(el.line.entryNo) ===
                parseInt(this.$route.query.entryNo)
              ) {
                this.timeSheetLines.comments = el.line.comments;
                el.totalHours = el.hours;
                el.entryNo = el.line.entryNo;
                el.periodStart = el.date;
              }
            }
            return {
              ...el,
            };
          });
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
@import url("../styles/styles.css");
</style>
